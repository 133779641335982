import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { ImageService } from './image.service';

export interface FileDownloadResponse {
  download_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private _apiUrl: string = environment.apiUrl;
  private _storageUrl: string = environment.storageUrl;

  private readonly _http = inject(HttpClient);
  private readonly _imageService = inject(ImageService);

  public downloadFile(file_name: string): Observable<FileDownloadResponse[] | string> {
    const webAddress = window.location.origin;
    const params = new HttpParams().append('web_address', webAddress).append('file_name', file_name);

    if (this._imageService.isImageCached(file_name)) {
      return of<any>(this._imageService.getImage(file_name));
    }

    return this._http
      .post<FileDownloadResponse[] | string>(`${this._apiUrl}file/download_public/`, {}, { params })
      .pipe(
        map((res: FileDownloadResponse[] | string) => {
          if (typeof res === 'string') {
            return res;
          }
          this._imageService.cacheImage(`${this._storageUrl}/download/${res[0].download_token}/`, file_name);

          return `${this._storageUrl}/download/${res[0].download_token}/`;
        }),
      );
  }

  public downloadFileToPC(file: string, filename: string): Observable<Blob> {
    return this.downloadFile(file).pipe(
      switchMap((url) => {
        if (typeof url === 'string') {
          return this._http.get(url, { responseType: 'blob' }).pipe(
            map((blob) => {
              saveAs(blob, filename);

              return blob;
            }),
          );
        } else {
          throw new Error('Invalid URL returned from downloadFile');
        }
      }),
    );
  }
}
