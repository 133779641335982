<div class="header-container">
  <div
    class="logo"
    tabindex="-1"
    (click)="logoClick()"
  >
    <img
      [src]="logo"
      alt="Company logo"
    />
  </div>
  <div class="navbar-wrapper">
    <app-navbar></app-navbar>
  </div>
  <div
    class="webtv-logo"
    routerLink="/"
    tabindex="-1"
  >
    <img
      src="../../../../assets/images/web-tv-logo.svg"
      alt="Web TV logo"
    />
  </div>
</div>
