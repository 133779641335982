import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ICachedImage {
  url: string;
  blob: Blob;
  timestamp: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private _cacheUrls: string[] = [];
  private _cachedImages: ICachedImage[] = [];
  private readonly _http = inject(HttpClient);
  private db: IDBDatabase | null = null;
  private readonly ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000;

  constructor() {
    this.openDatabase();
  }

  set cacheUrls(urls: string[]) {
    this._cacheUrls = [...urls];
  }
  get cacheUrls(): string[] {
    return this._cacheUrls;
  }

  set cachedImages(image: ICachedImage) {
    this._cachedImages.push(image);
    this.saveImageToIndexedDB(image);
  }

  isImageCached(filename: string): boolean {
    return this._cachedImages.some((image) => image.url === filename);
  }

  getImage(url: string): string | null {
    const image = this._cachedImages.find((image) => image.url === url);

    if (image) {
      return URL.createObjectURL(image.blob);
    }

    return null;
  }

  cacheImage(url: string, filename: string): void {
    if (!this.isImageCached(filename)) {
      this._http
        .get(url, { responseType: 'blob' })
        .pipe(
          tap((blob) => {
            const newImage = { url: filename, blob, timestamp: Date.now() };

            this._cachedImages.push(newImage);
            this.saveImageToIndexedDB(newImage);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  private openDatabase(): void {
    const request = indexedDB.open('imageCacheDB', 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('cachedImages')) {
        db.createObjectStore('cachedImages', { keyPath: 'url' });
      }
    };

    request.onsuccess = () => {
      this.db = request.result;
      this.loadCacheFromIndexedDB();
      this.cleanUpOldCache();
    };

    request.onerror = () => {
      console.error('Failed to open IndexedDB');
    };
  }

  private saveImageToIndexedDB(image: ICachedImage): void {
    if (!this.db) {
      console.error('IndexedDB is not available');

      return;
    }

    const transaction = this.db.transaction(['cachedImages'], 'readwrite');
    const store = transaction.objectStore('cachedImages');

    store.put(image);

    transaction.oncomplete = () => {};

    transaction.onerror = () => {
      console.error('Failed to save image to IndexedDB');
    };
  }

  private loadCacheFromIndexedDB(): void {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction(['cachedImages'], 'readonly');
    const store = transaction.objectStore('cachedImages');
    const request = store.getAll();

    request.onsuccess = () => {
      this._cachedImages = request.result as ICachedImage[];
    };

    request.onerror = () => {
      console.error('Failed to load cached images from IndexedDB');
    };
  }

  private cleanUpOldCache(): void {
    if (!this.db) {
      return;
    }

    const now = Date.now();

    const transaction = this.db.transaction(['cachedImages'], 'readwrite');
    const store = transaction.objectStore('cachedImages');
    const request = store.getAll();

    request.onsuccess = () => {
      const images = request.result as ICachedImage[];

      images.forEach((image) => {
        if (now - image.timestamp > this.ONE_WEEK_MS) {
          store.delete(image.url);
        }
      });

      this.loadCacheFromIndexedDB();
    };

    request.onerror = () => {
      console.error('Failed to clean up old cache');
    };
  }

  clearImageCache(): void {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction(['cachedImages'], 'readwrite');
    const store = transaction.objectStore('cachedImages');
    const clearRequest = store.clear();

    clearRequest.onsuccess = () => {
      this._cachedImages = [];
    };

    clearRequest.onerror = () => {
      console.error('Failed to clear IndexedDB cache');
    };
  }
}
