import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, NzI18nModule, en_US } from 'ng-zorro-antd/i18n';

import { ThemeState } from './store/theme.state';
import { VideoPlayerState } from './shared/components/video-details/store/video.state';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { ContactsComponent } from './shared/components/contacts/contacts.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { environment } from 'src/environments/environment';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { CustomTranslateLoader } from './translate-loader';
import { registerLocaleData } from '@angular/common';
import localeNo from '@angular/common/locales/no';
import localeNb from '@angular/common/locales/nb';
import localeNn from '@angular/common/locales/nn';
import { StreamTypeState } from './store/stream-type.state';
import { VideoTimeState } from './shared/components/video-details/store/video-time.state';

registerLocaleData(localeNo);
registerLocaleData(localeNb);
registerLocaleData(localeNn);

@NgModule({
  declarations: [AppComponent, HeaderComponent, NavbarComponent, ContactsComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    SpinnerComponent,
    NzI18nModule,
    NgxsModule.forRoot([ThemeState, VideoPlayerState, StreamTypeState, VideoTimeState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'no-NO',
    },
    {
      provide: LOCALE_ID,
      useValue: 'nb-NO',
    },
    {
      provide: LOCALE_ID,
      useValue: 'nn_NO',
    },
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
