import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GetVideoTime } from './video-time.action';
import { IVideoTime } from './interfaces';

@State<number>({
  name: 'videoTime',
  defaults: 0,
})
export class VideoTimeState {
  @Selector()
  static videoTime(state: IVideoTime): IVideoTime {
    return state;
  }

  @Action(GetVideoTime)
  setVideoTime({ patchState }: StateContext<IVideoTime>, { payload }: GetVideoTime) {
    patchState({ ...payload });
  }
}
