import { AppService } from './app.service';
import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { GetPortalTemplate, GetStreamTypes } from './store/app.action';
import { Observable, filter } from 'rxjs';
import { PortalTemplate } from './shared/interfaces/portal-template';
import { ThemeState } from './store/theme.state';
import { Title } from '@angular/platform-browser';
import { StreamType } from './shared/interfaces/stream';
import { IGetStreamTypes } from './shared/services/stream-type/models';
import { StreamTypeService } from './shared/services/stream-type/stream-type.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @Select(ThemeState.portalTemplate) portalTemplate$: Observable<PortalTemplate>;
  public portalTemplate: PortalTemplate;
  public isLoadingTemplate = false;
  public streamTypes: StreamType[] = [];
  public isUnavailable = false;
  private webAddress = window.location.origin;

  constructor(
    private translate: TranslateService,
    private appService: AppService,
    private store: Store,
    private titleService: Title,
    private streamTypeService: StreamTypeService,
    private ref: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
  ) {
    this.getPublicTemplate();
    translate.setDefaultLang('en');
    translate.use('en');
    this.getPortalTemplateSelector();
  }

  ngOnInit(): void {
    this.getStreamTypes();
  }

  private getPublicTemplate(): void {
    this.isLoadingTemplate = true;

    this.appService
      .getPortalTemplate(this.webAddress)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (response?.id) {
            this.translate.use(response.language);
            this.titleService.setTitle(response.name_of_tab);
            this.store.dispatch(new GetPortalTemplate(response));
            this.isUnavailable = false;
          }

          if (response.id && response.font) {
            this._renderer.setStyle(document.body, 'font-family', response.font + ', sans-serif');
          }

          this.isLoadingTemplate = false;
        },
        error: () => {
          this.isUnavailable = true;
          this.isLoadingTemplate = false;
        },
      });
  }

  private getPortalTemplateSelector(): void {
    this.portalTemplate$.pipe(filter(Boolean), untilDestroyed(this)).subscribe((data: PortalTemplate) => {
      this.portalTemplate = data;
    });
  }

  private getStreamTypes(): void {
    this.streamTypeService
      .getStreamTypes(this.webAddress)
      .pipe(untilDestroyed(this))
      .subscribe((response: IGetStreamTypes) => {
        this.store.dispatch(new GetStreamTypes([...response.stream_types]));
        this.ref.markForCheck();
      });
  }
}
